import React from "react";

export const Header = (props) => {
  return (
    <div className="intro">
      <header id="header">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="main-title">JL Construcción</h1>
                <p className="slogan">Construyendo el futuro con calidad y confianza</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

